import { useFormik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../../data/slices/modals";
import { RUN_RDP_DESKTOP_FIELDS as fields } from "./../../constants/fields";
import { InputText } from "../../../../components/InputFields";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const defaultCredentials = {
  hostname: process.env.REACT_APP_WINDOWS_HOSTNAME,
  username: process.env.REACT_APP_WINDOWS_USERNAME,
  password: process.env.REACT_APP_WINDOWS_PASSWORD
};

const RDPTemplateForm = ({ data }) => {
  const { t } = useTranslation("index");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      hostname: defaultCredentials.hostname,
      username: defaultCredentials.username,
      password: defaultCredentials.password
    },
    validationSchema: Yup.object().shape({
      hostname: Yup.string().required("Required"),
      username: Yup.string().required("Required"),
      password: Yup.string().required("Required")
    }),
    onSubmit: (values) => {
      dispatch(closeModal("template-modal-rdp"));
      navigate(`/control/rdp`, {
        state: values
      });
    }
  });

  return (
    <div className="template-form form">
      <form action="" onSubmit={formik.handleSubmit}>
        {fields.map((el, index) => (
          <InputText formik={formik} field={el} key={el.name} onClick />
        ))}
        <div className="action_form">
          <button
            type="reset"
            onClick={() => {
              dispatch(closeModal("template-modal-rdp"));
              formik.setValues({});
              formik.setErrors({ cancel: "true" });
            }}
            className="dashboard-cancel-btn"
          >
            {t("home.RDPTemplateForm-1")}
          </button>
          <button disabled={false} type="submit" className="dashboard-confirm-btn">
            {t("home.RDPTemplateForm-2")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default RDPTemplateForm;
